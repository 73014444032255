import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { SpecialistGuardService } from './services/auth-guard/specialist-guard.service';
import { AnonymousGuardService } from './services/auth-guard/anonymous-guard.service';
import { LoadChildrenCallbackReturnType } from '@app/types/load-children-callback-return-type';

const routes: Routes = [
  {
    path: 'dash/:workspaceId',
    loadChildren: (): LoadChildrenCallbackReturnType =>
      import('./components/dash/dash.module').then((m) => m.DashModule),
    canLoad: [AuthGuardService],
    data: {
      title: 'Dashboard'
    }
  },
  {
    path: 'specialist',
    loadChildren: (): LoadChildrenCallbackReturnType =>
      import('./components/specialist-mode/specialist-mode.module').then(
        (m) => m.SpecialistModeModule
      ),
    canLoad: [SpecialistGuardService],
    data: {
      title: 'Specialist Mode'
    }
  },
  {
    path: 'login',
    loadChildren: (): LoadChildrenCallbackReturnType =>
      import('./components/login/login.module').then((m) => m.LoginModule),
    canLoad: [AnonymousGuardService],
    data: {
      title: 'Login'
    }
  },
  {
    path: 'register',
    loadChildren: (): LoadChildrenCallbackReturnType =>
      import('./components/register/register.module').then(
        (m) => m.RegisterModule
      ),
    canLoad: [AnonymousGuardService],
    data: {
      title: 'Register'
    }
  },
  {
    path: 'permission-access',
    loadChildren: (): LoadChildrenCallbackReturnType =>
      import('./components/permission-access/permission-access.module').then(
        (m) => m.PermissionAccessModule
      ),
    data: {
      title: 'Permission Access'
    }
  },
  {
    path: 'forgot-password',
    loadChildren: (): LoadChildrenCallbackReturnType =>
      import('./components/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
    canLoad: [AnonymousGuardService],
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'forgot-verification',
    loadChildren: (): LoadChildrenCallbackReturnType =>
      import(
        './components/forgot-verification/forgot-verification.module'
      ).then((m) => m.ForgotVerificationModule),
    canLoad: [AnonymousGuardService],
    data: {
      title: 'Password Verification'
    }
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dash',
    redirectTo: 'dash/',
    pathMatch: 'full'
  },
  { 
    path: '**', 
    redirectTo: 'login' 
  } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
